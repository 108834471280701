import { ENV, DATADOG } from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const createAllowedUrlPatterns = (baseUrl) => [
  baseUrl,
  new RegExp(baseUrl.replace(/\//g, "\\/")),
  (url) => url.startsWith(baseUrl),
]

const envTag = ["local", "staging"].includes(ENV) ? "dev" : ENV

const DD_ALLOWED_URLS = createAllowedUrlPatterns(DATADOG.BASE_URL)

const init = () => {
  console.log("Datadog - started")
  datadogRum.init({
    applicationId: DATADOG.APPLICATION_ID,
    clientToken: DATADOG.CLIENT_TOKEN,
    allowedTracingUrls: DD_ALLOWED_URLS,
    site: "datadoghq.com",
    service: "itau-jn6-app-reneg-www",
    env: envTag,
    proxy: DATADOG.PROXY,
    sessionSampleRate: DATADOG.SESSION_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })
}
export default {
  init,
}
